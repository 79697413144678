import React from "react";
import styles from "./footer.module.scss";

// Components
import { ContactForm } from "../ContactForm/ContactForm";

// Images
import LogoDetailed from "../../Images/LogoDetailed_72.png";

export const Footer = () => {
  return (
    <div className={styles.footer} id="footer">
      <div className={styles.formHolder}>
        <ContactForm />
      </div>
      <div className={styles.navigation}>
        <div className={styles.linksContainer}>
          <h1 className={styles.header}>Quick Links</h1>
          <div className={styles.links}>
            <a className={styles.link} href="">
              Products
            </a>
            <a className={styles.link} href="">
              Get High?
            </a>
            <a className={styles.link} href="">
              Our Coordinates
            </a>
          </div>
        </div>
        <div className={styles.linksContainer}>
          <h1 className={styles.header}>Company</h1>
          <div className={styles.links}>
            <a className={styles.link} href="/">
              Home
            </a>
            <a className={styles.link} href="/learn">
              Learn
            </a>
            <a className={styles.link} href="/about">
              About Us
            </a>
          </div>
        </div>
        <div className={styles.linksContainer}>
          <h1 className={styles.header}>Help</h1>
          <div className={styles.links}>
            <a className={styles.link} href="">
              FAQs
            </a>
            <a className={styles.link} href="">
              Talk to an Expert
            </a>
            <a className={styles.link} href="">
              Get a Recommendation
            </a>
          </div>
        </div>
      </div>
      <div className={styles.logoContainer}>
        <img
          alt="weed leaf over wilderness background"
          className={styles.logo}
          src={LogoDetailed}
        />
        <h4 className={styles.copywright}>@ Copyright On The High End 2023</h4>
      </div>
    </div>
  );
};
