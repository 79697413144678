import React, { useContext, useRef } from "react";
import cx from "classnames";

// Context
import { NavigationContext } from "../../Context/navigationContext";

// CSS
import styles from "./mobile-nav.module.scss";

// Images
import LogoWithTextLight from "../../Images/LogoWithTextLight.png";

export const MobileNav = () => {
  const { closeNav, isNavOpen, useOutsideAlerter } =
    useContext(NavigationContext);

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <div
      ref={wrapperRef}
      className={cx(styles.mobileNav, {
        [styles["mobileNav--open"]]: isNavOpen,
      })}
    >
      <div
        className={cx(styles.navPanel, {
          [styles["navPanel--open"]]: isNavOpen,
        })}
      >
        <div
          className={styles.navigationCancel}
          tabIndex="0"
          onClick={closeNav}
        >
          X
        </div>
        <img alt="weed leaf" className={styles.logo} src={LogoWithTextLight} />
        <ul className={styles.navigationList}>
          <li className={styles.navigationItem}>
            <a href={"/"}>Home</a>
          </li>
          <li className={styles.navigationItem}>
            <a href={"/learn"}>Learn</a>
          </li>
          <li className={styles.navigationItem}>
            <a href={"/about"}>About</a>
          </li>
        </ul>
      </div>
    </div>
  );
};
