import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  Outlet,
  RouterProvider,
  useLocation,
} from "react-router-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

// Components
import { About } from "./Pages/About/About";
import { Footer } from "./Components/Footer/Footer";
// import { Header } from "./Components/Header/Header";
import { Home } from "./Pages/Home/Home";
import { Learn } from "./Pages/Learn/Learn";
import { Modal } from "./Components/Modal/Modal";
import { MobileNav } from "./Components/MobileNav/MobileNav";

// Context
import { NavigationProvider } from "./Context/navigationContext";

// CSS
import "./animate.scss";

// Helpers
import { scrollAnimation } from "./Helpers/Animate";
import ScrollToTop from "./Helpers/ScrollToTop";

// Images
import LogoWithText from "./Images/HeaderLogoBlack2.png";

function Layout() {
  const [isAgeVerifyModalOpen, setIsAgeVerifyModal] = useState(false);

  let location = useLocation();

  useEffect(() => {
    scrollAnimation(".animate", {
      rootMargin: "75px",
      threshold: 0,
    });
    const isVerified = localStorage.getItem("isVerified");
    setIsAgeVerifyModal(!isVerified);
  }, [location]);

  return (
    <>
      <Modal
        accpetButtonFunction={() => {
          setIsAgeVerifyModal(false);
          localStorage.setItem("isVerified", "true");
        }}
        acceptButtonText="Yes"
        className="test"
        declineButtonFunction={() => setIsAgeVerifyModal(false)}
        declineButtonText="No"
        displayed={isAgeVerifyModalOpen}
        headerImage={LogoWithText}
        bodyText="Are you age 21+ or have a medical marijuana card?"
        footerText={`By entering this site I accept the terms and conditions.`}
      />
      <ScrollToTop />
      <MobileNav />
      <Outlet />
      <Footer />
    </>
  );
}

const router = createBrowserRouter([
  {
    element: <Layout />,
    // errorElement: <ErrorPage />,
    children: [
      {
        path: "/*",
        element: <Home />,
      },
      {
        path: "/learn",
        element: <Learn />,
      },
      {
        path: "/about",
        element: <About />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <NavigationProvider>
      <RouterProvider router={router} />
    </NavigationProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
