import React, { useRef, useState } from "react";
import styles from "./contact-form.module.scss";
import emailjs from "@emailjs/browser";

export const ContactForm = () => {
  const [contactFormData, setContactFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const form = useRef();

  function handleChange(e) {
    const { name, value } = e.target;
    setContactFormData({ ...contactFormData, [name]: value });
  }

  function handleSubmit(e) {
    e.preventDefault();

    if (contactFormData.email === "") {
      alert("Email must be filled out");
      return;
    }

    emailjs
      .sendForm(
        "service_a8fd16d",
        "contact_form",
        form.current,
        "xxmXxliQT1MA2Gf1Y"
      )
      .then(
        (result) => {
          alert("Message Sent!");
        },
        (error) => {
          console.log(error.text);
        }
      );
  }

  return (
    <div className={styles.contactForm}>
      <div className={styles.textHolder}>
        <div className={styles.header}>
          Questions? Comments? Want to learn more about us?
        </div>
        <p className={styles.bodyText}>
          Leave your name and email and we will keep you in the loop.
        </p>
      </div>
      <form
        ref={form}
        className={styles.form}
        onSubmit={(e) => handleSubmit(e)}
      >
        <input
          className={styles.input}
          aria-label="Name"
          onChange={handleChange}
          placeholder="Name"
          name="name"
          type="text"
          value={contactFormData.name}
        />
        <input
          className={styles.input}
          aria-label="Email"
          onChange={handleChange}
          placeholder="Email"
          name="email"
          type="text"
          value={contactFormData.email}
        />
        <textarea
          className={styles.textarea}
          aria-label="Message"
          onChange={handleChange}
          placeholder="Write your message here..."
          name="message"
          rows="4"
          type="text"
          value={contactFormData.message}
        />
        <button
          className={styles.button}
          onClick={(e) => handleSubmit(e)}
          type="submit"
        >
          Stay in the loop!
        </button>
      </form>
    </div>
  );
};
