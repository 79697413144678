import React from "react";
import styles from "./about.module.scss";

// Components
import { FullPageImage } from "../../Components/FullPageImage/FullPageImage";
import { Header } from "../../Components/Header/Header";
import { TextImageSection } from "../../Components/TextImageSection/TextImageSection";
import { ImageTextSection } from "../../Components/ImageTextSection/ImageTextSection";
import { NoPaddingSection } from "../../Components/NoPaddingSection/NoPaddingSection";

import FieldOfGrowingWeed from "../../Images/FieldOfGrowingWeed_72.jpg";
import LocallyGrownSign from "../../Images/LocallyGrownSign2.png";
import HandsOnTree from "../../Images/HandsOnTree.jpg";
import FlagInField from "../../Images/FlagInField3.png";
import LogoGray from "../../Images/Header-Logo-Leaf_GRAY.png";

export const About = () => {
  return (
    <div className={styles.about}>
      <Header classNames={styles.header} />
      <FullPageImage
        backgroundImage={FieldOfGrowingWeed}
        bodyText="We are passionate about what we do and wish to share
              all this plant has to provide. Come enjoy our local Minnesota variety."
        headerText="A High End Experience"
      />
      <TextImageSection
        altImgText="local grown, fresh from the farm sign on pole"
        bodyText="Just like your favorite local microbreweries,
              we look to provide all visitors with true Minnesota grown, high end cannabis.
              Locally bred and grown, we're proud of our efforts and enjoy sharing that dedication with all patrons."
        headerText="Locally Grown"
        imgSrc={LocallyGrownSign}
      />
      <ImageTextSection
        altImgText="many hands on a log"
        bodyText="We're happy to say that our success is really a joint, local effort.
              Once the door for businesses opened, we assembled a team of passionate community
              members who shared our vision for bringing a quality, high end cannabis experience to the people."
        headerText="Community Driven"
        imgSrc={HandsOnTree}
      />
      <NoPaddingSection
        altImgText="american flag in a field"
        backgroundDood={LogoGray}
        bodyText="We're proud of our veteran roots and thank those that have, and continue to serve."
        headerText="Veteran Owned"
        imgSrc={FlagInField}
      />
    </div>
  );
};
