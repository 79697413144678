import React from "react";
import styles from "./home.module.scss";
import { useNavigate } from "react-router-dom";

// Components
import { FullPageImage } from "../../Components/FullPageImage/FullPageImage";
import { Header } from "../../Components/Header/Header";
import { ImageTextSection } from "../../Components/ImageTextSection/ImageTextSection";
import { NoPaddingSection } from "../../Components/NoPaddingSection/NoPaddingSection";
import { TextImageSection } from "../../Components/TextImageSection/TextImageSection";

// Carousel
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { CarouselCard } from "../../Components/Carousel/CarouselCard";

// Images
import BeardedMenWeedFarming from "../../Images/BeardedMenWeedFarming_72.jpg";
import Crumble from "../../Images/Crumble.jpg";
import FieldOfGrowingWeed from "../../Images/FieldOfGrowingWeed_72.jpg";
import FlagInField from "../../Images/FlagInField3.png";
import GirlOnMountain from "../../Images/GirlOnMountain.jpg";
import Gummy from "../../Images/WeedGummy.jpg";
import HandsOnTree from "../../Images/HandsOnTree.jpg";
import Hash from "../../Images/Hash.jpg";
import Indica from "../../Images/Indica.jpg";
import LocallyGrownSign from "../../Images/LocallyGrownSign2.png";
import LogoGray from "../../Images/Header-Logo-Leaf_GRAY.png";
import Nug from "../../Images/Nug.png";
import PurpleTippedBud from "../../Images/PurpleTippedBud.jpg";
import Sativa from "../../Images/Sativa.jpg";
import Topicals from "../../Images/Topicals.jpg";
import WeedInHands from "../../Images/weedInHands.jpg";

export const Home = () => {
  const navigate = useNavigate();

  const images = [
    Crumble,
    FieldOfGrowingWeed,
    FlagInField,
    HandsOnTree,
    LocallyGrownSign,
    Indica,
  ];
  images.forEach((i) => {
    var img = new Image();
    img.src = i.url;
  });

  return (
    <div className={styles.home}>
      <Header classNames={styles.header} />
      <FullPageImage
        backgroundImage={BeardedMenWeedFarming}
        bodyText="A place to gather and enjoy quality,
              locally grown cannabis. From your favorites, to new MN strains,
              come see what we have to offer and what other fun activities await."
        buttonFunction={() => navigate("/learn")}
        buttonText="View Our Products"
        button2Function={() => navigate("/home#footer")}
        button2Text="Stay Up To Date"
        headerText="Like No Other"
      />
      <TextImageSection
        altImgText="two hands holding a small weed leaf"
        backgroundDecal={Nug}
        bodyText="What was once an idea, now exists here in Minnesota. As microbusinesses begin to flourish,
              so too does the local variety. There are many strains that we all have come to know and love,
              but we now have a new range of Minnesota flavors."
        headerText="A New Path"
        imgSrc={WeedInHands}
      />
      <ImageTextSection
        altImgText="purple tipped buds of a growing weed leaf"
        backgroundDecal={LogoGray}
        bodyText="No matter if you've been enjoying cannabis for years or if it is something you now want to try,
              we'd love to be able to show you what this plant has to offer."
        buttonFunction={() => navigate("/about")}
        buttonText="Learn More About Us"
        imgSrc={PurpleTippedBud}
      />
      <NoPaddingSection
        altImgText="man farming in a greenhouse"
        backgroundDecal={LogoGray}
        bodyText="From our 5000 sq/ft growery, to the tasting lounge and frolf course,
              if you're looking for a more high end experience, we know just the place.
              We have a wide selection of activities for all to enjoy.
              We have our own growery, breeding new local strains of flower and other naturally derived products.
              Not to mention other outdoor activities to enjoy, such as music and frolf.
              We also provide learning opportunities for those that want to try their hand at growing something they can enjoy."
        headerText="A New Experience"
        imgSrc={GirlOnMountain}
      />
      <div className={styles.learnCarousel}>
        <div className={`${styles.titleCarousel} animate`}>
          <h1 className={styles.title}>Our Products</h1>
          <p className={styles.description}>
            All our products are naturally derived.
          </p>
        </div>

        <Carousel
          infiniteLoop
          swipeable
          emulateTouch
          centerMode
          centerSlidePercentage={50}
          onClickItem={(index) => navigate(`/learn#${index}`)}
          renderArrowNext={(clickHandler) => (
            <button className={styles.carouselButton} onClick={clickHandler}>
              &rang;
            </button>
          )}
          renderArrowPrev={(clickHandler, hasPrev, labelPrev) => (
            <button className={styles.carouselButton} onClick={clickHandler}>
              &lang;
            </button>
          )}
        >
          <CarouselCard headerText="Flower" imgSrc={Sativa} />
          <CarouselCard headerText="Consumables" imgSrc={Gummy} />
          <CarouselCard headerText="Concentrates" imgSrc={Hash} />
          <CarouselCard headerText="Topicals" imgSrc={Topicals} />
        </Carousel>
      </div>
    </div>
  );
};
