import React from "react";
import cx from "classnames";
import styles from "./banner.module.scss";

export const Banner = ({ bodyText, classNames, headerText, id }) => {
  return (
    <div className={cx(styles.banner, classNames)} id={id}>
      <div className={styles.textContainer}>
        <div className={styles.headerText}>{headerText}</div>
        <p className={styles.bodyText}>{bodyText}</p>
      </div>
    </div>
  );
};
