import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToHashElement = () => {
  let location = useLocation();

  // let hashElement = useMemo(() => {
  //   let hash = location.hash;
  //   const hashId = hash.replace("#", "");

  //   if (hash) {
  //     let element = document.getElementById(hashId);
  //     return element;
  //   } else {
  //     return null;
  //   }
  // }, [location]);

  useEffect(() => {
    let hashElement;
    let hash = location.hash;
    const hashId = hash.replace("#", "");
    if (hash) {
      hashElement = document.getElementById(hashId);
    }
    if (hashElement) {
      hashElement.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    } else {
      document.documentElement.scrollTo({
        top: 0,
        left: 0,
        behavior: "instant",
      });
    }
  }, [location]);

  return null;
};

export default ScrollToHashElement;
