import React from "react";
import styles from "./no-padding-section.module.scss";

export const NoPaddingSection = ({
  altImgText,
  backgroundDecal,
  backgroundDood,
  bodyText,
  headerText,
  imgSrc,
}) => {
  return (
    <div className={styles.noPaddingSection}>
      {backgroundDecal && (
        <img
          alt="background doodle"
          className={styles.backgroundDecal}
          src={backgroundDecal}
        />
      )}
      {backgroundDood && (
        <img
          alt="background doodle About"
          className={styles.backgroundDood}
          src={backgroundDood}
        />
      )}
      <div className={styles.textImageContainer}>
        <div className={styles.twoElementRowItem}>
          <div className={`${styles.textContainer} animate`}>
            <h1 className={styles.header}>{headerText}</h1>
            <p className={styles.paragraph}>{bodyText}</p>
          </div>
        </div>
        <div className={styles.twoElementRowItem}>
          <div className={styles.imageContainer}>
            <img className={styles.image} src={imgSrc} alt={altImgText} />
          </div>
        </div>
      </div>
    </div>
  );
};
