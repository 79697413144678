import React from "react";
import styles from "./full-page-image.module.scss";

export const FullPageImage = ({
  backgroundImage,
  bodyText,
  buttonFunction,
  buttonText,
  button2Function,
  button2Text,
  headerText,
}) => {
  return (
    <div
      className={styles.fullPageImage}
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundImage: `linear-gradient(
            to bottom,
            rgba(32, 32, 32, 0.25),
            rgba(0, 0, 0, 0.65)
          ),
          url(${backgroundImage})`,
      }}
    >
      <div className={`${styles.contentContainer} animate`}>
        <h1 className={styles.header}>{headerText}</h1>
        <p className={styles.paragraph}>{bodyText}</p>
        <div className={styles.buttons}>
          {buttonText && (
            <button className={styles.button} onClick={buttonFunction}>
              {buttonText}
            </button>
          )}
          {button2Text && (
            <button className={styles.button} onClick={button2Function}>
              {button2Text}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
