import React from "react";
import styles from "./image-text-section.module.scss";

export const ImageTextSection = ({
  altImgText,
  backgroundDecal,
  bodyText,
  buttonFunction,
  buttonText,
  headerText,
  imgSrc,
}) => {
  return (
    <div className={styles.imageTextSection}>
      {backgroundDecal && (
        <img
          alt="background doodle"
          className={styles.backgroundDecal}
          src={backgroundDecal}
        />
      )}
      <div className={styles.imageTextContainer}>
        <div className={styles.twoElementRowItem}>
          <div className={styles.imageContainer}>
            <img className={styles.image} src={imgSrc} alt={altImgText} />
          </div>
        </div>
        <div className={`${styles.twoElementRowItem} animate`}>
          <div className={styles.textContainer}>
            {headerText && <h1 className={styles.header}>{headerText}</h1>}
            <p className={styles.paragraph}>{bodyText}</p>
          </div>
          <div className={styles.buttons}>
            {buttonText && (
              <button className={styles.button} onClick={buttonFunction}>
                {buttonText}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
