import React from "react";
import styles from "./learn.module.scss";

// Components
import { Banner } from "../../Components/Banner/Banner";
import { Header } from "../../Components/Header/Header";
import { ImageTextSection } from "../../Components/ImageTextSection/ImageTextSection";

// Images
import Indica from "../../Images/Indica.jpg";
import Sativa from "../../Images/Sativa.jpg";
import Gummy from "../../Images/WeedGummy.jpg";
import Crumble from "../../Images/Crumble.jpg";
import Hash from "../../Images/Hash.jpg";
// import Shatter from "../../Images/Shatter.jpg";
import Topicals from "../../Images/Topicals.jpg";
import WeedChocolate from "../../Images/WeedChocolate_1.jpg";

export const Learn = () => {
  return (
    <div className={styles.learn}>
      <Header classNames={styles.header} lightMode={false} />
      <div className={styles.bannerContainer}>
        <Banner
          bodyText="Personal Effects May Vary"
          // classNames={styles.bannerFlower}
          headerText="Flower"
          id="0"
        />
      </div>
      <ImageTextSection
        altImgText="indica weed bud"
        bodyText="Indicas are your body high. Great for relaxation and pain relief and can
             help if you're having trouble sleeping."
        headerText="Indica"
        imgSrc={Indica}
      />
      <ImageTextSection
        altImgText="sativa weed bud"
        bodyText="Sativas are your head/mind high. Great for creativity and being more active
         if you like hiking or other activities."
        headerText="Sativa"
        imgSrc={Sativa}
      />
      <Banner
        bodyText="Personal Effects May Vary"
        // classNames={styles.bannerConsumables}
        headerText="Consumables"
        id="1"
      />
      <ImageTextSection
        altImgText="weed chocolate bar"
        bodyText="Chocolate is a great edible alternative to smoking or vaping cannabis flower/concentrates.
             It takes longer to affect you and can last longer so start small!"
        headerText="Chocolates"
        imgSrc={WeedChocolate}
      />
      <ImageTextSection
        altImgText="weed gummies"
        bodyText="Another sweet treat, gummies are a great way to consume cannabis.
         Easy to dose and find your optimal level."
        headerText="Gummies"
        imgSrc={Gummy}
      />

      <Banner
        bodyText="Personal Effects May Vary"
        // classNames={styles.bannerConcentrates}
        headerText="Concentrates"
        id="2"
      />
      <ImageTextSection
        altImgText="Crumble"
        bodyText="Concentrates are pretty straightforward, just a more concentrated amount of THC.
              You could consider cannabis flower the 'beer' of the cannabis world and concentrate extracts more like your 'spirits'.
              There are many ways to consume concentrates such as dabbing or in vape cartridges
              as well as combining with cannabis flower."
        headerText="Crumble & Shatter"
        imgSrc={Crumble}
      />
      {/* <ImageTextSection
        altImgText="shatter"
        bodyText="Indica is like ‘in da couch’ like straight couch lock chasing
                      waterfalls and milkers straight to your dome."
        headerText="Shatter"
        imgSrc={Shatter}
      /> */}
      <ImageTextSection
        altImgText="hash"
        bodyText="Another derivative of extracts, hash is derived from what makes cannabis flower so crystalized, trichomes.
              Can be consumed in similar ways to crumbles and shatter concentrates."
        headerText="Hash"
        imgSrc={Hash}
      />

      <Banner
        bodyText="Personal Effects May Vary"
        // classNames={styles.bannerTopicals}
        headerText="Topicals"
        id="3"
      />
      <ImageTextSection
        altImgText="topicals"
        bodyText="Topicals are normally higher in CBD meant for pain management.
             Great for relief from sore and stiff muscles and aches after a long hike."
        headerText="Lotions, Creams, Oils"
        imgSrc={Topicals}
      />

      {/* <ImageCarousel /> */}
      {/* <div>
        <Carousel autoPlay> 
          <CarouselCard
          headerText="Crumble"
          imgSrc={Crumble}
          />
          <CarouselCard
          headerText="Shatter"
          imgSrc={Shatter}
          />
          <CarouselCard
          headerText="Hash"
          imgSrc={Hash}
          />
        </Carousel>
      </div> */}
    </div>
  );
};
