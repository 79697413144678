export function scrollAnimation(selector, options = {}) {
  let component = document.querySelectorAll(selector);
  component = Array.from(component);
  component.forEach((comp) => {
    addObserver(comp, options);
    // comp.addEventListener("animationend", function () {
    //   comp.classList.remove("active");
    // });
  });
}

function addObserver(comp, options) {
  if (!("IntersectionObserver" in window)) {
    // Simple fallback
    // The animation/callback will be called immediately so
    // the scroll animation doesn't happen on unsupported browsers
    if (options.cb) {
      options.cb(comp);
    } else {
      comp.target.classList.add("active");
    }
    // We don't need to execute the rest of the code
    return;
  }
  let observer = new IntersectionObserver((entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add("active");
        // observer.unobserve(entry.target);
      } else {
        // setTimeout(() => {
        entry.target.classList.remove("active");
        // }, "250");
      }
    });
  }, options);
  observer.observe(comp);
}
