import { createContext, useEffect, useState } from "react";

export const NavigationContext = createContext({});

export const NavigationProvider = (props) => {
  const { children } = props;

  const [isNavOpen, setIsNavOpen] = useState(false);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsNavOpen(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  function closeNav() {
    setIsNavOpen(false);
    const scrollY = document.body.style.top;
    document.body.style.top = "";
    document.body.style.position = "";
    window.scrollTo(0, parseInt(scrollY || "0") * -1);
  }

  const navigationContext = {
    closeNav,
    isNavOpen,
    setIsNavOpen,
    useOutsideAlerter,
  };
  return (
    <NavigationContext.Provider value={navigationContext}>
      {children}
    </NavigationContext.Provider>
  );
};
