import React, { useContext } from "react";
import cx from "classnames";
import { useNavigate } from "react-router-dom";

// Components
import { HamburgerMenu } from "../Icons/HamburgerMenu";

// Context
import { NavigationContext } from "../../Context/navigationContext";

// CSS
import styles from "./header.module.scss";

// Images
import LogoWithTextDark from "../../Images/LogoWithTextDark.png";
import LogoWithTextLight from "../../Images/LogoWithTextLight.png";

export const Header = ({ classNames, lightMode = true, menuIconColor }) => {
  const navigate = useNavigate();
  const { isNavOpen, setIsNavOpen } = useContext(NavigationContext);

  function openNav() {
    setIsNavOpen(true);
    document.body.style.top = `-${window.scrollY}px`;
    document.body.style.position = "fixed";
  }

  return (
    <div className={styles.header}>
      <div
        className={cx(styles.navBar, classNames, {
          [styles["navBar--hidden"]]: isNavOpen,
        })}
      >
        <a className={styles.logoNav}>
          <img
            alt="weed leaf with on the high end text"
            className={styles.logo}
            src={LogoWithTextLight}
          />
        </a>
        <div className={styles.navButtons}>
          <button className={styles.navButton} onClick={() => navigate("/")}>
            HOME
          </button>
          <button
            className={styles.navButton}
            onClick={() => navigate("/learn")}
          >
            LEARN
          </button>
          <button
            className={styles.navButton}
            onClick={() => navigate("/about")}
          >
            ABOUT
          </button>
        </div>
        <button className={styles.hamburgerMenuContainer} onClick={openNav}>
          <HamburgerMenu menuIconColor={menuIconColor} />
        </button>
      </div>
    </div>
  );
};
