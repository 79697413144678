import React from "react";
import cx from "classnames";
import styles from "./modal.module.scss";

export const Modal = ({
  accpetButtonFunction,
  acceptButtonText,
  bodyText,
  closeModal,
  declineButtonFunction,
  declineButtonText,
  displayed,
  footerText,
  headerImage,
  headerText,
}) => {
  if (displayed) {
    return (
      <div className={styles.modal}>
        <div className={styles.background}>
          <div className={styles.header}>
            {headerImage && (
              <div className={styles.imageHolder}>
                <img
                  className={styles.logo}
                  src={headerImage}
                  alt="weed leaf and slogan"
                />
              </div>
            )}
            {headerText}
          </div>
          <div className={styles.bodyText}>{bodyText}</div>
          <div className={styles.buttons}>
            <button
              className={cx(styles.button, styles["button--accept"])}
              onClick={accpetButtonFunction}
            >
              {acceptButtonText}
            </button>
            <button className={styles.button} onClick={declineButtonFunction}>
              {declineButtonText}
            </button>
          </div>
          <div className={styles.footer}>{footerText}</div>
        </div>
      </div>
    );
  }
  return null;
};
