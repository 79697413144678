import React from "react";
import styles from "./carousel-card.module.scss";
import "./carousel-card.scss";

export const CarouselCard = ({
    bodyText,
    headerText,
    imgSrc,
    numSlide,
  }) => {
    return (
        <div className={styles.carouselCard}>
            <img className={styles.carouselImg} src={imgSrc} alt=""/>
            {/* <p>{bodyText}</p> */}
            <div className={styles.legends}>
                <p className="legend">{headerText}</p>
            </div>
        </div>
    )
    }
